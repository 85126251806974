"use client";

import { ReactNode } from 'react';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { faFacebookF, faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css';

library.add(
  // Regular
  faSpinnerThird, faInfoCircle,
  // Brands
  faFacebookF, faXTwitter, faLinkedinIn
)

config.autoAddCss = false;

const FontAwesomeProvider = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

export default FontAwesomeProvider;
