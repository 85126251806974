import React from 'react';
import { useField } from 'formik';
import { InputProps } from '../../common/interfaces';

export const Input = ({type = 'text', size = 'default', label, value = '', placeholder, isDisabled = false, onChange, ...props}: InputProps) => {
  const stylesList: string[] = ['bg-white text-slate-900 disabled:bg-slate-50 disabled:text-slate-500 disabled:ring-slate-200 block border w-full rounded-lg sm:leading-6' +
  ' disabled:cursor-not-allowed']
  const sizeStyle = getSize(size);
  const [field, meta] = useField(props);

  function getSize(size: string): string {
    switch (size) {
      case 'sm':
        return 'py-1 px-3 text-xs';
      case 'lg':
        return 'py-3 px-6';
      case 'xl':
        return 'py-4 px-6';
      default:
        return 'py-2.5 px-4 text-sm';
    }
  }

  return (
    <div className='flex flex-col'>
      { label &&
        <div className='block -mb-3 z-10 ml-1.5'>
          <label htmlFor={field.name} className={['text-xs font-medium text-slate-800 leading-6 px-2', isDisabled ? 'bg-slate-50 rounded-md border border-slate-300' : 'bg-white'].join(' ')}>
            { label }
          </label>
        </div>
      }

      <input
        {...field}
        {...props}
        type={type}
        name={field.name}
        className={[stylesList.join(' '), meta.touched && meta.error ? ' border-red-500' : 'border-slate-300', sizeStyle].join(' ')}
        placeholder={placeholder}
        disabled={isDisabled}
      />

      { meta.touched && meta.error ? (
        <p className="mt-1 text-xs text-red-500 flex-wrap">
          { meta.error }
        </p>
      ) : null}
    </div>
  );
};