import env from 'react-dotenv'

const WEBHOOK_URL = env.GOOGLE_CHAT_WEBHOOK_URL as string

export async function sendMessageToGoogleChat(message: { text: string }) {
  try {
    const response:Response = await fetch(WEBHOOK_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    throw new Error(`Ooops! Looks like something went wrong!`)
  }
}