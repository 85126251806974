import React from 'react';
import EnrollmentForm from './components/EnrollmentForm';
import {Icon} from './components/UI';

function App() {
  return (
    <div className="flex md:h-dvh flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              alt="Warehouse411"
              src="/logo.svg"
              className="h-20 w-auto"
            />
            <h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Warehouse Dashboard <br/>Early Registration
            </h2>
            <div className="flex items-center mt-6 bg-blue-50 text-blue-500 font-medium rounded-lg text-sm p-6">
              <span className='flex pr-4'>
                <Icon prefix='far' icon='circle-info' size='xl' />
              </span>
              <span className='flex flex-1'>This part of our application is currently under active development. Fill the form and we'll reach out to you as soon as it&apos;s ready.</span>
            </div>
          </div>

          <div className="mt-6">
            <EnrollmentForm />
          </div>

          <div className='flex justify-center text-xs text-gray-500 mt-8'>
            <span>© 2024 &bull; Warehouse411, Inc. All rights reserved</span>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          alt=""
          src="/office.jpg"
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </div>
  );
}

export default App;
