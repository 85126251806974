import { object, string } from 'yup';

const phoneNumberRegex:RegExp = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/;
const zipRegex:RegExp = /^\d{5}$/;

export const preregistrationFormValidationSchema = object().shape({
  company:
    string()
      .label('Company')
      .required()
      .trim(),
  zip:
    string()
      .required()
      .label('Company ZIP')
      .matches(zipRegex, {message: 'ZIP must be in format XXXXX'}),
  fullName:
    string()
      .label('Full Name')
      .required()
      .trim(),
  email:
    string()
      .label('E-mail')
      .required()
      .email()
      .trim(),
  phoneNumber:
    string()
      .required()
      .label('Phone number')
      .matches(phoneNumberRegex, {message: 'Your phone number must be in format +1 (XXX) XXX-XXXX'}),
})