'use client'

import React from 'react';
import { ButtonProps } from '../../common/interfaces';
import clsx from 'clsx'
import { Icon } from '../../components/UI/Icon';

export const Button = ({
   type = 'button',
   size = 'md',
   color = 'default',
   isFullwidth = false,
   isLoading = false,
   onClick,
   innerRef,
   children
 }: ButtonProps) => {
  const baseStyles: string[] = ['rounded-lg font-semibold transition-colors duration-500']
  const sizeStyle = getSize(size);
  const colorStyle = getColor(color);
  const fullwidthStyle = isFullwidth ? 'w-full' : '';

  function getSize(size: string): string {
    switch (size) {
      case 'xs':
        return 'px-2 py-1 text-xs';
      case 'sm':
        return 'px-2 py-1.5 text-sm';
      case 'lg':
        return 'px-3.5 py-2.5 text-sm';
      case 'xl':
        return 'px-4 py-3 text-sm';
      default:
        return 'px-3 py-2.5 text-sm';
    }
  }

  function getColor(color: string): string {
    switch (color) {
      case 'blue':
        return 'bg-blue-500 text-white border border-transparent hover:text-blue-500 hover:bg-white hover:border-blue-500 focus-visible:outline-border-blue';
      case 'red':
        return 'bg-red-500 border border-transparent text-white hover:text-red-500 hover:bg-white hover:border-red-500';
      case 'white':
        return 'bg-white border border-gray-300 hover:bg-blue-500 text-blue-500 hover:text-white';
      default:
        return 'bg-neutral-900 border border-transparent text-white hover:bg-neutral-700 hover:border-neutral-700';
    }
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(baseStyles, sizeStyle, colorStyle, fullwidthStyle)}
    >
      { isLoading
        ?
        <Icon prefix='far' icon='spinner-third' size='lg' spin />
        :
        children
      }
    </button>
  );
};