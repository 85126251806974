import React from 'react';
import { Formik, Form } from 'formik';
import { preregistrationFormInitialValues as initialValues } from '../common/data';
import { preregistrationFormValidationSchema as validationSchema } from '../common/validationSchemas';
import { trimPhoneNumber } from '../common/helpers';
import {Button, Input, MaskedInput} from './UI';
import { sendMessageToGoogleChat } from '../services/GoogleService';
import { toast } from 'react-toastify';

const EnrollmentForm = () => {

  async function sendMessage(payload: any): Promise<void> {
    const {company, zip, fullName, email, phoneNumber} = payload
    const cleanedPhone: string = trimPhoneNumber(phoneNumber)

    const msg:{text:string} = {
      text:`*Warehouse Early Registration*

*Company*: ${company}
*ZIP:* ${zip}
*Name:* ${fullName}
*E-mail*: ${email}
*Phone Number:* ${cleanedPhone}
`,
    };

    return sendMessageToGoogleChat(msg)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        sendMessage(values)
          .then(() => toast.success('You was successfully registered! See you soon!'))
          .catch(() => toast.error('Something went wrong. Try again later.'))
          .finally(() => {
            setSubmitting(false)
            resetForm()
          })
      }}
      >
      {(props => (
        <Form className='flex flex-col gap-y-4'>
          <div className='grid grid-cols-4 gap-4'>
            <div className='col-span-3'>
              <Input name='company' label='Company' />
            </div>
            <div className='col-span-1'>
              <MaskedInput name='zip' label='ZIP' mask='99999' />
            </div>
          </div>
          <Input name='fullName' label='Full Name' />
          <Input name='email' label='E-mail' />
          <MaskedInput name='phoneNumber' label='Phone Number' mask='+1 (999) 999-9999' placeholder='+1 (___) ___-____' />
          <Button type='submit' color='red' isLoading={props.isSubmitting}>Send</Button>
        </Form>
      ))}
    </Formik>
  );
};

export default EnrollmentForm;